<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>Мы почти уже у цели. Ловите следующее занятие )</p>
            <h3>8.1 Шьем красную полочку</h3>
        </div>

        <VideoView video-id="74731546e81448c186184b67165d6647"/>

        <div class="MBtextcont">
            <h3>8.2 Шьем красный подол</h3>
        </div>

        <VideoView video-id="e983c1c4802f42f0948b6ed35335cfeb"/>

        <div class="MBtextcont">
            <h3>8.3 Соединяем полочку с подолом</h3>
        </div>

        <VideoView video-id="69295cb7fd13489ba5209c39ed5c0bd4"/>

        <div class="MBtextcont">
            <h3>8.4 Плессированный воротник</h3>
        </div>

        <VideoView video-id="c3ef8ae736e04f9f83eee59ef29086b5"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>